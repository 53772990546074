import { gql } from '@apollo/client';

import { IMultipleChoiceOption, IPaginatedResponse, IPaginatedVars, ISlide } from '../../../../types';

export interface GetRepCardSlideParticipationResultData {
  getRepCardSlideParticipationResult: IPaginatedResponse<{
    answerMediaUrl;
    answerText: string;
    multipleChoiceOption: IMultipleChoiceOption;
    repCardSlide: ISlide;
    user: {
      userName: string;
      avatarUrl: string | null;
    };
  }>;
}

export interface GetRepCardSlideParticipationResultVars extends IPaginatedVars {
  repCardSlideId: number;
}

export const GET_REPCARD_SLIDE_PARTICIPATION_RESULT = gql`
  query GetRepCardSlideParticipationResult($repCardSlideId: Int!, $skip: Int, $take: Int) {
    getRepCardSlideParticipationResult(repCardSlideId: $repCardSlideId, skip: $skip, take: $take) {
      items {
        answerMediaUrl
        answerText
        multipleChoiceOption {
          id
          imageUrl
          type
          title
        }
        repCardSlide {
          id
          multipleChoiceOptions {
            id
            imageUrl
            title
            type
          }
          position
          questionTitle
          type
        }
        user {
          avatarUrl
          userName
          id
        }
      }
      pagination {
        skip
        take
        total
      }
    }
  }
`;
