import { Box, Button, Flex, Grid, Image, Text } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import LockImage from '../../assets/onboarding/lock.gif';
import Logo from '../../components/Logo';
import ContentModal from '../../components/Modals/Content/ContentModal';
import PageLayout from '../../components/PageLayout';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from '../../utils';

type BrandAgeModalProps = {
  brandParticipationAge: number;
  brandName: string;
  isOpen: boolean;
  onAccept: (submitted?: boolean) => void;
};

const BrandAgeModal: React.FC<BrandAgeModalProps> = ({ brandParticipationAge, brandName, isOpen, onAccept }) => {
  const size = useWindowSize();
  const theme = useTheme();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const router = useRouter();

  const [showButtons, setShowButtons] = useState<boolean>(true);

  const isUnderMinAge = useCallback(() => {
    setShowButtons(false);
    setTimeout(() => router.push('/'), 300);
  }, []);

  const isOverMinAge = useCallback(() => {
    onAccept();
  }, [brandName, onAccept]);

  return (
    <Flex flexDirection="column" width="100%">
      <ContentModal
        isOpen={isOpen}
        fullScreen={true}
        body={
          <Box p={!isMobile ? '24px' : '0'} overflow="hidden">
            <PageLayout hideNavbar showSignup={false} centerLogo showLogo minimal disableLogoRedirect>
              <Flex flexDirection="column" justifyItems="center" width="100%" height={!isMobile ? '94%' : '100%'}>
                <Grid mb="20px" height="100%" gridTemplateColumns={isMobile ? '1fr' : '2fr 1fr'}>
                  {!isMobile && (
                    <Flex justifyContent="center" alignItems="center" flexDirection="column" mr="40px">
                      <Image src={LockImage} maxWidth={460} maxHeight={460} />
                    </Flex>
                  )}
                  <Flex
                    data-testid="modal-logged-off-user-age-validation-painel"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    elevation="high"
                    borderRadius="25px"
                    px="5px"
                    width={!isMobile ? '549px' : size.width - 30}
                    __css={
                      !isMobile
                        ? {
                            'box-shadow': '0px 8px 16px rgb(0 0 0 / 16%)',
                          }
                        : {
                            'box-shadow': 'none !important',
                          }
                    }
                  >
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      pt={!isMobile ? '40px' : '0'}
                    >
                      <Logo height={!isMobile ? 50 : 25} />
                    </Flex>
                    <Flex flexDirection="column" justifyContent="center" flex={1} width="100%" alignItems="center">
                      {isMobile && <Image src={LockImage} maxWidth={201} maxHeight={201} />}
                      {showButtons && (
                        <>
                          <Text variant="text3-400" textAlign="center">
                            Are you over {brandParticipationAge}?
                          </Text>
                          <Text
                            textAlign="center"
                            maxWidth={!isMobile ? '65%' : '75%'}
                            variant="text5-400"
                            mt="2"
                            color="#828282"
                          >
                            {`To access ${brandName} on TYB, we need `}
                            <br />
                            {`you to verify if you are over ${brandParticipationAge} years old.`}
                          </Text>
                          <Button
                            type="submit"
                            //fontSize="15px"
                            scale="sm"
                            maxWidth={209}
                            mt={30}
                            mb={14}
                            px={44}
                            py={10}
                            onClick={() => isOverMinAge()}
                          >
                            Yes, I am over {brandParticipationAge}.
                          </Button>
                          <Button
                            type="submit"
                            //fontSize="15px"
                            scale="sm"
                            maxWidth={209}
                            px={44}
                            py={10}
                            backgroundColor="#ffffff"
                            color="#000000"
                            border="1px solid #C7C7C7"
                            onClick={isUnderMinAge}
                          >
                            No, I am under {brandParticipationAge}.
                          </Button>
                        </>
                      )}
                      {!showButtons && (
                        <>
                          <Text variant="text3-400" textAlign="center">
                            {`We’re sorry, you`}
                            <br />
                            {`can’t enter the page!`}
                          </Text>
                          <Text
                            textAlign="center"
                            maxWidth={!isMobile ? '65%' : '75%'}
                            variant="text5-400"
                            mt="2"
                            mx={!isMobile ? '26%' : '0'}
                            color="#828282"
                          >
                            Redirecting you to TYB...
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Grid>
              </Flex>
            </PageLayout>
          </Box>
        }
      />
    </Flex>
  );
};

export default BrandAgeModal;
