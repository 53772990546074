import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { BRAND_AGE_GATE_VERIFICATION_KEY } from 'src/constants';
import {
  VERIFY_BRAND_PARTICIPATION_REQUIREMENTS,
  verifyBrandParticipationRequirementsData,
  verifyBrandParticipationRequirementsVars,
} from 'src/graphql/queries/verifyBrandParticipationRequirements';
import { IBrand } from 'src/interface/IBrand';
import BrandAgeModal from 'src/modals/BrandAgeModal/BrandAgeModal';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { addBrandAgeConfirmation } from 'src/redux/session/sessionSlice';

const useBrandRequerimentVerification = (brand: Pick<IBrand, 'uuid' | 'minParticipationAge' | 'name'>) => {
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState(0);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const cookieValue = () => {
    const value = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${BRAND_AGE_GATE_VERIFICATION_KEY}=`))
      ?.split('=')[1];

    return (value && JSON.parse(value)) || {};
  };

  const setCookieValue = () => {
    document.cookie = `${BRAND_AGE_GATE_VERIFICATION_KEY}=${JSON.stringify({
      ...cookieValue(),
      [brand.uuid]: true,
    })};path=/;domain=${(process.env.NEXT_PUBLIC_DOMAIN || '').replace('app.', '')}`;
  };

  const brandAgeConfirmations = useAppSelector((state) => state.session.brandAgeConfirmations);

  const hasConfirmed = useMemo(
    () => cookieValue()[brand?.uuid] || brandAgeConfirmations[brand?.uuid],
    [cookieValue, brandAgeConfirmations[brand?.uuid], brand?.uuid]
  );

  const [onConfirmResolve, setOnConfirmResolve] = useState<{
    resolve: (args: { confirmed: boolean }) => void;
  } | null>(null);

  const { refetch } = useQuery<verifyBrandParticipationRequirementsData, verifyBrandParticipationRequirementsVars>(
    VERIFY_BRAND_PARTICIPATION_REQUIREMENTS,
    {
      fetchPolicy: 'standby',
    }
  );

  const close = useCallback(
    (args: { confirmed: boolean }) => {
      onConfirmResolve?.resolve(args);
      setOpen(false);
      setOnConfirmResolve(null);
    },
    [open, setOpen]
  );

  const modal = useMemo(
    () => (
      <BrandAgeModal
        isOpen={open}
        onAccept={() => {
          close({ confirmed: true });
        }}
        brandParticipationAge={age}
        brandName={brand?.name}
      />
    ),
    [open, age, brand?.name]
  );

  const verifyLoggedInRequirements = useCallback(async () => {
    setLoading(true);
    const result = await refetch({
      brandUuid: brand.uuid,
    });
    setLoading(false);

    if (result?.data?.verifyBrandParticipationRequirements?.needAgeVerification) {
      setAge(result?.data?.verifyBrandParticipationRequirements?.needAgeVerificationData?.minParticipationAge || 0);

      const isConfirmed = await new Promise<{ confirmed: boolean }>((resolve) => {
        setOnConfirmResolve({ resolve });
        setOpen(true);
      });

      if (isConfirmed.confirmed) {
        if (isConfirmed.confirmed) {
          setCookieValue();

          dispatch(addBrandAgeConfirmation(brand.uuid));
        }
      }

      return isConfirmed.confirmed;
    } else {
      return true;
    }
  }, [brand?.uuid, setLoading, refetch, setAge, setOnConfirmResolve, setOpen]);

  const verifyLoggedOutRequirements = useCallback(async () => {
    setAge(brand.minParticipationAge);

    const isConfirmed = await new Promise<{ confirmed: boolean }>((resolve) => {
      setOnConfirmResolve({ resolve });
      setOpen(true);
    });

    if (isConfirmed.confirmed) {
      setCookieValue();

      dispatch(addBrandAgeConfirmation(brand.uuid));
    }

    return isConfirmed.confirmed;
  }, [brand?.uuid, brand?.minParticipationAge, setAge, setOnConfirmResolve, setOpen]);

  const attempt = useCallback(
    async (loggedIn: boolean) => {
      if (hasConfirmed || !brand.minParticipationAge) {
        return true;
      }

      if (loggedIn) {
        return verifyLoggedInRequirements();
      } else {
        return verifyLoggedOutRequirements();
      }
    },
    [refetch, setOnConfirmResolve, setLoading, brand, hasConfirmed]
  );

  return {
    modal,
    attempt,
    loading,
    hasConfirmed,
  };
};

export default useBrandRequerimentVerification;
