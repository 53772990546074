import { Box, Button, Flex, Input, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useRef, useState } from 'react';
import { getSupportedMediaMimeTypes } from 'src/utils/file';

import { MultipartUploadStatus } from '../../../types';
import FileUploader, { FileUploaderRef } from '../../FileUploader/FileUploader';
import Title from '../Components/TitleSlide';

interface MediaUploadSlide {
  name?: string;
  brandName?: string;
  showBorders?: boolean;
  onSubmit?: (uploadedUrl: string, caption: string) => any;
  loading?: boolean;
}

const MediaUploadSlide: React.FC<MediaUploadSlide> = ({
  name,
  brandName,
  showBorders = true,
  onSubmit,
  loading = false,
}) => {
  const [uploadStatus, setUploadStatus] = useState<{ status: MultipartUploadStatus; progress: number }>({
    status: 'IDLE',
    progress: 0,
  });
  const fileUploaderRef = useRef<FileUploaderRef>();
  const [caption, setCaption] = useState<string>(undefined);

  const handleUploaderChange = useCallback(
    (status, file, uploadedUrl, progress) => {
      if (status === 'FINISHED') {
        onSubmit(uploadedUrl, caption);
      } else {
        setUploadStatus({
          status,
          progress: progress || 0,
        });
      }
    },
    [onSubmit]
  );

  const changeCaption = (e) => {
    const text = e.target.value;
    setCaption(text);
  };

  const submit = () => {
    if (!caption || (caption && caption?.length <= 140)) {
      fileUploaderRef.current.upload();
    }
  };

  const supportedMimeTypes = getSupportedMediaMimeTypes();

  return (
    <Box
      data-testid="challenge-slide"
      border={showBorders ? 'thin' : 'none'}
      borderRadius="md"
      overflow="hidden"
      bg="white"
      pt="2"
      width="100%"
      p="2"
    >
      {name && (
        <Flex flexDirection="column" p="2" justifyContent="space-between">
          <Flex flex={1}>
            <Title value={name} />
          </Flex>
        </Flex>
      )}

      <Box width="100%" minHeight="130px" mt="5" data-testid="challenge-media-upload-slide">
        <FileUploader
          ref={fileUploaderRef}
          folder="repcards-response"
          accept={supportedMimeTypes}
          onChange={handleUploaderChange}
        />
      </Box>
      <Box mt={2}>
        <Input
          as="textarea"
          placeholder="Describe your upload or add a link..."
          name="response-answer"
          height={88}
          value={caption}
          onChange={(e) => changeCaption(e)}
        />
        <Flex flexDirection={'row'} justifyContent={'end'} alignItems={'center'}>
          <Text
            variant="text6-400"
            textAlign={'end'}
            color={caption?.length >= 141 ? '#DF2B00' : caption?.length >= 130 ? '#EEA432' : undefined}
          >
            {caption?.length | 0}/140
          </Text>
        </Flex>
      </Box>

      <Box mt="5">
        <Button
          width="100%"
          disabled={
            !fileUploaderRef?.current ||
            uploadStatus.status === 'PREPARING' ||
            uploadStatus.status === 'UPLOADING' ||
            caption?.length > 140
          }
          onClick={submit}
        >
          {(loading || uploadStatus.status === 'PREPARING') && 'Loading...'}
          {!loading && uploadStatus.status === 'UPLOADING' && `Uploading: ${uploadStatus.progress}%`}
          {!loading && uploadStatus.status !== 'PREPARING' && uploadStatus.status !== 'UPLOADING' && 'SUBMIT'}
        </Button>

        {brandName && (
          <Text variant="text6-400" textAlign="center" px="8px">
            By submitting, you consent to {brandName} using your media in marketing materials.
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default MediaUploadSlide;
