import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';

import { apollo } from '../config/apolloClient';
import {
  SAVE_SOCIAL_AUTH_TOKEN,
  SaveSocialAuthTokenData,
  SaveSocialAuthTokenVars,
} from '../graphql/mutations/saveSocialAuthToken';
import { GET_SOCIAL_USER, GetSocialUserData, GetSocialUserVars } from '../graphql/queries/getSocialUser';
import {
  GET_SOCIAL_AUTH_LINK,
  GetSocialAuthLinkData,
  GetSocialAuthLinkVars,
  VERIFY_SOCIAL_AUTH_TOKEN,
  VerifySocialAuthTokenData,
  VerifySocialAuthTokenVars,
} from '../graphql/queries/verifySocialAuthToken';
import { ISocialPlatforms } from '../types';

const useSocialAuth = () => {
  const [loadingVerifyToken, setLoadingVerifyToken] = useState(false);

  const [saveSocialAuthToken, { loading: loadingSaveSocialAuthToken }] = useMutation<
    SaveSocialAuthTokenData,
    SaveSocialAuthTokenVars
  >(SAVE_SOCIAL_AUTH_TOKEN);

  const { loading: loadingSocialUser, refetch: getSocialUser } = useQuery<GetSocialUserData, GetSocialUserVars>(
    GET_SOCIAL_USER,
    {
      fetchPolicy: 'standby',
    }
  );

  const { loading: loadingSocialAuthLink, refetch: getSocialAuthLink } = useQuery<
    GetSocialAuthLinkData,
    GetSocialAuthLinkVars
  >(GET_SOCIAL_AUTH_LINK, {
    fetchPolicy: 'standby',
  });

  const verifyToken = async (platform: ISocialPlatforms, brandUuid: string) => {
    try {
      setLoadingVerifyToken(true);
      const { data } = await apollo.client.query<VerifySocialAuthTokenData, VerifySocialAuthTokenVars>({
        query: VERIFY_SOCIAL_AUTH_TOKEN,
        variables: {
          platform,
          brandUuid,
        },
        fetchPolicy: 'no-cache',
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoadingVerifyToken(false);
    }
  };

  return {
    saveSocialAuthToken,
    verifyToken,
    getSocialUser,
    getSocialAuthLink,
    loadingSocialAuthLink,
    loadingSocialUser,
    loadingSaveSocialAuthToken,
    loadingVerifyToken,
  };
};

export default useSocialAuth;
