import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';

import ImageCDN from '../../../components/ImageCDN';

interface ISlideOptionResultProps {
  title: string;
  isSelected: boolean;
  percent: number;
  image?: string;
}

const SlideOptionResult: React.FC<ISlideOptionResultProps> = ({ title, isSelected, percent }) => {
  return (
    <Flex
      borderWidth="1px"
      borderStyle="solid"
      borderColor="black"
      borderRadius="32px"
      p="2"
      width="100%"
      px="3"
      position="relative"
      overflow="hidden"
      alignSelf="center"
      __css={{ background: `linear-gradient(90deg, #E5E5E5 ${percent}%, #FFFFFF ${percent}%)` }}
    >
      <Flex width="100%" height="100%" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          {isSelected && (
            <Box mr="1" data-testid="challenge-selected-option">
              <Icon.BoxiconsRegular.CheckCircle size={18} />
            </Box>
          )}

          <Text variant="text4-400" fontWeight="bold">
            {title}
          </Text>
        </Flex>
        <Text variant="text4-400">{percent}%</Text>
      </Flex>
    </Flex>
  );
};

interface ISlideMultipleOptionResultProps {
  results: ISlideOptionResultProps[];
}

const SlideMultipleOptionsResult: React.FC<ISlideMultipleOptionResultProps> = ({ results }) => {
  return (
    <Box>
      {results.map((result) => (
        <Flex key={result.title} my="2">
          {result.image && (
            <Box width="128px" mr="2">
              <ImageCDN
                src={result.image}
                width="100%"
                height="100%"
                __css={{ objectFit: 'cover', aspectRatio: '1/1' }}
              />
            </Box>
          )}
          <SlideOptionResult
            title={result.title}
            isSelected={result.isSelected}
            percent={result.percent}
            image={result.image}
          />
        </Flex>
      ))}
    </Box>
  );
};

export default SlideMultipleOptionsResult;
