import { Button, Flex, Icon, Input, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useRef, useState } from 'react';

import InfiniteScroll from '../../containers/InfiniteScroll';
import { ISearchComments } from '../../graphql/queries/comments';
import useComments from '../../hooks/useComments/useComments';
import { useAppSelector } from '../../redux/hooks';
import { InteractivitySourceType } from '../../types';
import { getTimeDuration } from '../../utils';
import UserAvatar from '../UserAvatar';

const Comments: React.FC<{
  uuid: string;
  type: InteractivitySourceType;
  total: number;
  onSubmit?: () => void;
  openUserProfileTab?: (a) => void;
}> = ({ uuid, type, total: totalComments, onSubmit = () => null, openUserProfileTab }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { create, listQuery, listRecentCommentsQuery } = useComments({ uuid, type });
  const [getListRecentComments, { data: getListRecentCommentsData }] = listRecentCommentsQuery;

  const [text, setText] = useState('');
  const containerRef = useRef();

  const user = useAppSelector(({ user }) => user);

  const sendMessage = useCallback(async () => {
    try {
      if (text) {
        setLoading(true);
        await create(text);
        await loadMostRecentMessages();
        setText('');

        onSubmit();
      }
    } catch (error) {
      console.error('Error adding comment...', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [create, loading]);

  const listenEnter = async (e) => {
    if (e.key === 'Enter') {
      await sendMessage();
    }
  };

  function handleChange(e) {
    e.preventDefault();
    setText(e.target.value);
  }

  const loadMostRecentMessages = async () => {
    await getListRecentComments({
      variables: { type, uuid, take: 20, referenceUuid: listQuery?.data?.searchComments[0]?.uuid },
    });
  };

  if (listQuery.loading || totalComments === undefined) {
    return <>...</>;
  }

  return (
    <>
      <Flex
        flexDirection="column"
        mt={1}
        style={{ width: '100%' }}
        data-testid="comments-component"
        flex={1}
        justifyContent="space-between"
      >
        <Flex
          ref={containerRef}
          flexDirection="column"
          overflowY="auto"
          flex="1 1"
          maxHeight={['30vh', '20vh']}
          minHeight="10vh"
        >
          {totalComments > 0 ? (
            <InfiniteScroll
              containerRef={containerRef}
              itemKey="uuid"
              items={[...(getListRecentCommentsData?.searchRecentComments || []), ...listQuery.data?.searchComments]}
              itemPerFetch={20}
              total={totalComments}
              loadMore={async ({ take, skip }) => {
                const { data } = await listQuery.refetch({
                  take,
                  skip,
                });
                return data.searchComments;
              }}
            >
              {(list: ISearchComments[]) => (
                <>
                  {list
                    .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
                    .map((comment) => {
                      const timeAgo = getTimeDuration(comment.createdAt);
                      const duration: string = Number(timeAgo[0]) > 0 ? `${timeAgo.join(' ')} ago` : 'Just now';

                      return (
                        <Flex key={comment.uuid} mb={2} alignItems="start" mt="1">
                          <Flex
                            __css={{ cursor: 'pointer' }}
                            onClick={() => openUserProfileTab({ urlSlug: comment.user.urlSlug })}
                          >
                            <UserAvatar avatarUrl={comment.user.avatarUrl} />
                          </Flex>
                          <Flex flex="1" flexDirection="column" ml={2}>
                            <Flex alignItems="center" __css={{ gap: '5px' }}>
                              <Text
                                fontWeight="bold"
                                onClick={() => openUserProfileTab({ urlSlug: comment.user.urlSlug })}
                                __css={{ cursor: 'pointer' }}
                              >
                                {comment.user.userName}
                              </Text>
                              <Text variant="text6-400" color="gray04" mt="2px">
                                {duration}
                              </Text>
                            </Flex>

                            <Text>{comment.text}</Text>
                          </Flex>
                          {/* <Hi5
                            type="TOPIC_COMMENT"
                            uuid={comment.uuid}
                            total={comment.interactivity?.totalHi5s || 0}
                            // liked={comment.interactivity?.hi5ed || false} //TODO
                          /> */}
                        </Flex>
                      );
                    })}
                </>
              )}
            </InfiniteScroll>
          ) : (
            <Text color="neutrals-700" pt={3}>
              Be the first to comment!
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="row" mt={2} alignItems="center">
        <UserAvatar avatarUrl={user.avatarUrl} />
        <Flex width="100%" ml={2} justifyContent="center" flex={1}>
          <Input
            placeholder="Add a comment..."
            name="newComment"
            value={text || ''}
            onChange={handleChange}
            onKeyDown={listenEnter}
            style={{ borderRadius: '8px 0 0 8px' }}
          />
          <Button style={{ borderRadius: '0 8px 8px 0' }} disabled={!text || loading} onClick={() => sendMessage()}>
            <Icon.BoxiconsSolid.Send size={16} style={{ transform: 'rotate(315deg)', marginBottom: '2px' }} />
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Comments;
