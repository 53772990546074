import { gql } from '@apollo/client';

import { ISocialPlatforms } from '../../types';

export interface SaveSocialAuthTokenVars {
  platform: ISocialPlatforms;
  token: string;
  brandUuid: string;
}

export interface SaveSocialAuthTokenData {
  saveSocialAuthToken: boolean;
}

export const SAVE_SOCIAL_AUTH_TOKEN = gql`
  mutation saveSocialAuthToken($platform: String!, $brandUuid: String!, $token: String!) {
    saveSocialAuthToken(platform: $platform, brandUuid: $brandUuid, token: $token)
  }
`;
