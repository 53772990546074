import { useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useEffect, useState } from 'react';

import HorizontalScroll from '../../../components/HorizontalScroll';
import ImageCDN from '../../../components/ImageCDN';
import Loading from '../../../components/Loading';
import UserAvatar from '../../../components/UserAvatar';
import VideoCDN from '../../../components/VideoCDN';
import { IPaginatedResponse, SlideTypes } from '../../../types';
import {
  GET_REPCARD_SLIDE_PARTICIPATION_RESULT,
  GetRepCardSlideParticipationResultData,
  GetRepCardSlideParticipationResultVars,
} from '../graphql/queries/getRepCardSlideParticipationResult';

interface ISlideTextProps {
  answer: string;
  type: SlideTypes;
  author: {
    userName: string;
    avatarUrl?: string;
  };
}

const SlideResult: React.FC<ISlideTextProps> = ({ answer, type, author }) => {
  return (
    <Flex flexDirection="column">
      {type === 'MEDIA_UPLOAD' && (
        <Box width="256px" height="256px" mt="2" mr="2" data-testid="challenge-answered-media-upload">
          {answer.endsWith('.mp4') || answer.endsWith('.mov') ? (
            <VideoCDN
              src={answer}
              width="100%"
              borderRadius="8px"
              style={{ objectFit: 'cover', aspectRatio: '1/1' }}
              height="100%"
            />
          ) : (
            <ImageCDN
              src={answer}
              width="100%"
              borderRadius="8px"
              style={{ objectFit: 'cover', aspectRatio: '1/1' }}
              height="100%"
            />
          )}
        </Box>
      )}

      {type === 'TEXT_RESPONSE' && (
        <Flex
          flexGrow={1}
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray02"
          p="2"
          width="256px"
          px="3"
          position="relative"
          overflow="hidden"
          alignSelf="center"
          mr="2"
          flexDirection="column"
        >
          <Text variant="text4-400">{answer}</Text>
        </Flex>
      )}
      <Flex alignItems="center" mt="2">
        <UserAvatar avatarUrl={author.avatarUrl} />
        <Text ml="1">{author.userName}</Text>
      </Flex>
    </Flex>
  );
};

interface ISlideAnswerResultProps {
  slideId: number;
}

const SlideAnswerResult: React.FC<ISlideAnswerResultProps> = ({ slideId }) => {
  const [paginatedParticipationResult, setPaginatedParticipationResult] = useState<IPaginatedResponse<any>>({
    items: [],
    pagination: {
      take: 0,
      skip: 0,
      total: 0,
    },
  });

  const [endReached, setEndReached] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<
    GetRepCardSlideParticipationResultData,
    GetRepCardSlideParticipationResultVars
  >(GET_REPCARD_SLIDE_PARTICIPATION_RESULT, {
    fetchPolicy: 'no-cache',
    variables: {
      repCardSlideId: slideId,
      take: 5,
      skip: 0,
    },
  });

  useEffect(() => {
    if (endReached) {
      if (paginatedParticipationResult.items.length < paginatedParticipationResult.pagination.total) {
        refetch({
          repCardSlideId: slideId,
          take: 5,
          skip: paginatedParticipationResult.items.length,
        });
        setEndReached(false);
      }
    }
  }, [endReached]);

  useEffect(() => {
    if (data?.getRepCardSlideParticipationResult) {
      const items = [...(paginatedParticipationResult.items || []), ...data.getRepCardSlideParticipationResult.items];

      setPaginatedParticipationResult({
        items,
        pagination: data.getRepCardSlideParticipationResult.pagination,
      });
    }
  }, [data]);

  return (
    <HorizontalScroll
      height={220}
      onReachToEnd={() => setEndReached(true)}
      infinityScroll={{
        reachToEnd:
          paginatedParticipationResult.items.length == data?.getRepCardSlideParticipationResult?.pagination.total,
      }}
    >
      <Flex height="100%" alignSelf="flex-start" justifyContent="stretch">
        {(!data || loading) && <Loading />}

        {!loading &&
          paginatedParticipationResult.items.map((result, index) => (
            <SlideResult
              key={index}
              answer={result.answerText || result.answerMediaUrl}
              type={result.repCardSlide.type}
              author={result.user}
            />
          ))}
      </Flex>
    </HorizontalScroll>
  );
};

export default SlideAnswerResult;
