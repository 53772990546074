import { gql } from '@apollo/client';

import { IInteractivity, IMultipleChoiceOption, ISlide } from '../../../../types';
export interface GetRepCardParticipationResultVarsData {
  getRepCardParticipationResult: {
    user: { id: number };
    aggregatedAnswers?: {
      count: number;
      total: number;
      multipleChoiceOption: IMultipleChoiceOption;
    }[];
    myAnwers: {
      answerMediaUrl: string;
      answerText: string;
      interactivity: IInteractivity;
      multipleChoiceOption: IMultipleChoiceOption;
      repCardSlide: ISlide;
      user: {
        userName: string;
        avatarUrl: string | null;
      };
    }[];
    slide: ISlide;
  }[];
}

export interface GetRepCardParticipationResultVars {
  repCardId: number;
}

export const GET_REPCARD_PARTICIPATION_RESULT = gql`
  query GetRepCardParticipationResult($repCardId: Int!) {
    getRepCardParticipationResult(repCardId: $repCardId) {
      aggregatedAnswers {
        count
        total
        multipleChoiceOption {
          id
          imageUrl
          title
          type
        }
      }
      myAnwers {
        answerMediaUrl
        answerText
        interactivity {
          totalHi5s
          totalComments
          uuid
        }
        multipleChoiceOption {
          id
          imageUrl
          title
          type
        }
        repCardSlide {
          id
          multipleChoiceOptions {
            id
            imageUrl
            title
            type
          }
          position
          questionTitle
          type
        }
        user {
          userName
          avatarUrl
          id
        }
      }
      slide {
        id
        multipleChoiceOptions {
          id
          imageUrl
          title
          type
        }
        position
        questionTitle
        type
      }
    }
  }
`;
