import { Box } from '@tyb-u/tyb-ui-components';
import React from 'react';

const Progress: React.FC<{ percentage: number }> = ({ percentage }) => {
  return (
    <Box width="100%" height="5px" borderRadius="3px" backgroundColor="gray02">
      <Box width={`${percentage}%`} height="5px" borderRadius="3px" backgroundColor="black" />
    </Box>
  );
};

export default Progress;
