import { useQuery } from '@apollo/client';

import {
  GET_SOCIAL_SHARE_INSTRUCTIONS,
  GetSocialShareInstuctionsData,
  GetSocialShareInstuctionsVars,
} from '../graphql/getRepCardSocialShareInstuctions';

const useRepcardSocialShareInstructions = (
  repCardId: number
): { data: GetSocialShareInstuctionsData; loading: boolean } => {
  const { data, loading } = useQuery<GetSocialShareInstuctionsData, GetSocialShareInstuctionsVars>(
    GET_SOCIAL_SHARE_INSTRUCTIONS,
    {
      variables: {
        repCardId,
      },
    }
  );

  return {
    data,
    loading,
  };
};

export { useRepcardSocialShareInstructions };
