import { Box, Text } from '@tyb-u/tyb-ui-components';
import { fonts } from '@tyb-u/tyb-ui-components/theme';
import isHtml from 'is-html';
import React from 'react';

type TitleSize = 'small' | 'normal' | 'big';
interface TitleSlideProps {
  value: string;
  size?: TitleSize;
}

const getTextVariant = (size: TitleSize) => {
  if (size === 'small') return 'text6-400';
  if (size === 'normal') return 'text5-400';
  if (size === 'big') return 'text4-400';
  return 'text5-400';
};

const getTextWeight = (size: TitleSize) => {
  if (size === 'big') return '600';
  return '400';
};

const TitleSlide: React.FC<TitleSlideProps> = ({ value, size = 'normal' }) => {
  const html = isHtml(value);

  return (
    <Box
      width="100%"
      __css={{
        a: { color: '#0000EE', 'text-decoration': 'underline' },
        p: {
          ...(fonts['text5-400'] as any),
          padding: '0',
          paddingBottom: '4px',
        },
        // strong: {
        //   fontWeight: 600,
        // },
        // ul: { ...(fonts['text5-400'] as any) },
        // ol: { ...(fonts['text5-400'] as any) },
      }}
    >
      {html ? (
        <div className="dangerous_html" dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <Text variant={getTextVariant(size)} fontWeight={getTextWeight(size)} pb="0">
          {value}
        </Text>
      )}
    </Box>
  );
};

export default TitleSlide;
