import { gql } from '@apollo/client';
import { IUser } from 'src/interface/IUser';

import { ISocialPlatforms } from '../../../types';

export interface ISocialShareInstruction {
  id: number;
  uuid: string;
  instruction: string;
  hashtags: string[];
  tags: string[];
  platform: ISocialPlatforms;
  platformId: number;
}

export interface GetSocialShareInstuctionsData {
  repCard: {
    socialShareInstructions: ISocialShareInstruction;
    participationCount: number;
    participantUsersHighlight: IUser[];
  };
}

export interface GetSocialShareInstuctionsVars {
  repCardId: number;
}

export const GET_SOCIAL_SHARE_INSTRUCTIONS = gql`
  query getRepCardSocialShareInstuctions($repCardId: Int!) {
    repCard(id: $repCardId) {
      socialShareInstructions {
        id
        uuid
        instruction
        hashtags
        tags
        platform
        platformId
      }
      completedParticipationCount: participationCount
      participantUsersHighlight {
        id
        avatarUrl
      }
    }
  }
`;
