import { gql } from '@apollo/client';
import { IInteractivity, InteractivitySourceType } from 'src/types';

export interface IInteractivityCountersData {
  interactivity: IInteractivity;
}

export interface IInteractivityCountersVars {
  sourceUuid: string;
  sourceType: InteractivitySourceType;
}

export const INTERACTIVITY_COUNTERS = gql`
  query Interactivity($sourceType: InteractivitySourceType!, $sourceUuid: String!) {
    interactivity(type: $sourceType, uuid: $sourceUuid) {
      uuid
      totalComments
      totalHi5s
    }
  }
`;
