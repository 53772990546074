import { useMutation, useQuery } from '@apollo/client';
import mParticle from '@mparticle/web-sdk';
import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useBrandRequerimentVerification from 'src/hooks/useBrandRequerimentVerification';
import { logMparticleEvent } from 'src/utils/mparticle';
import { useTheme } from 'styled-components';

import Loading from '../../components/Loading';
import ContentModal from '../../components/Modals/Content';
import MediaUploadSlide from '../../components/RepCard/Slides/MediaUploadSlide';
import MultipleChoiceSlide from '../../components/RepCard/Slides/MultipleChoiceSlide';
import ReferenceSlide from '../../components/RepCard/Slides/ReferenceSlide';
import TextResponseSlide from '../../components/RepCard/Slides/TextResponseSlide';
import StepWizard from '../../components/StepWizard';
import {
  ADD_REPCARD_PARTICIPATION,
  IAddRepcardParticipationData,
  IAddRepcardParticipationVars,
} from '../../graphql/mutations/addRepcardParticipation';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { RepCardRewardMetadata, RewardTypes } from '../../types';
import { convertRemToPx } from '../../utils';
import GalleryViewModal from '../GalleryViewModal/GalleryViewModal';
import RepCardResultsModal from '../RepCardResultsModal';
import SocialShareModal from '../SocialShareModal/SocialShareModal';
import UnlockRewardModalContent from '../UnlockRewardModalContent/UnlockRewardModalContent';
import Progress from './components/Progress';
import { GET_REP_CARD, RepCard, RepCardData, RepCardVars } from './graphql/queries/getRepCard';
import { MAP_REP_REWARD_TYPE_TO_MPARTICLE_EVENT } from './mapRepRewardTypeToMParticleEvent';

type StepNameType =
  | 'LOADING'
  | 'LOADED_INITIAL_DATA'
  | 'GALLERY'
  | 'SOCIAL_SHARE'
  | 'PRODUCT_PURCHASE'
  | 'SLIDES'
  | 'UNLOCK'
  | 'RESULTS';

export type RepCardCompleteModalCloseStatus =
  | 'REDIRECT_TO_STORE'
  | 'AGE_RESTRICTION_DISMISS'
  | 'DISMISS'
  | 'CLOSE'
  | 'COMPLETED';

interface CompleteRepCardModalProps {
  id: string;
  brandUuid: string;
  rewardType: RewardTypes;
  rewardMetadata?: RepCardRewardMetadata;
  rewardTokenId?: string;
  isOpen: boolean;
  isSimulation?: boolean;
  showButtonOnFinish?: boolean;
  onClose: (status: RepCardCompleteModalCloseStatus, hasNewCompletedParticipation?: boolean) => void;
  onPressButtonOnFinish?: () => void;
  hasCompleted?: boolean;
  repeatParticipations?: boolean;
}

const CompleteRepCardModal: React.FC<CompleteRepCardModalProps> = ({
  id,
  rewardMetadata,
  isOpen,
  isSimulation = false,
  showButtonOnFinish,
  onClose,
  onPressButtonOnFinish,
  hasCompleted: initialHasCompleted,
  repeatParticipations,
}) => {
  const size = useWindowSize();
  const theme = useTheme();
  const fullScreen = size.width <= convertRemToPx(theme.breakpoints[0]);

  const [stepName, setStepName] = useState<StepNameType>('LOADING');
  const [currentStep, setCurrentStep] = useState(1);
  const [isRestarting, setIsRestarting] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const {
    data: dataRepcard,
    refetch: refetchRepcard,
    loading: isLoading,
  } = useQuery<RepCardData, RepCardVars>(GET_REP_CARD, {
    fetchPolicy: 'no-cache',
    skip: !id || !isOpen,
    variables: {
      id: parseInt(id),
    },
  });

  const [addRepcardParticipation, { loading: loadingAddParticipation }] = useMutation<
    IAddRepcardParticipationData,
    IAddRepcardParticipationVars
  >(ADD_REPCARD_PARTICIPATION);

  const requiresApproval = dataRepcard?.repCard.reward?.requiresApproval;
  const canResubmit = dataRepcard?.repCard?.reward?.canResubmit;
  const hasApprovedParticipation = dataRepcard?.repCard?.myConsolidatedParticipation?.hasBeenApproved;
  const isRewarded = dataRepcard?.repCard?.myConsolidatedParticipation?.totalRewarded > 0;

  const isCompleted = !!dataRepcard?.repCard?.myConsolidatedParticipation && !isRestarting;

  const totalSteps = useMemo(
    () =>
      dataRepcard?.repCard ? dataRepcard.repCard.slides.length + (dataRepcard.repCard.showReferenceImages ? 1 : 0) : 1,
    [dataRepcard?.repCard?.id]
  );

  const { modal, attempt, hasConfirmed } = useBrandRequerimentVerification(dataRepcard?.repCard.brand);

  const restartSurvey = () => {
    setIsRestarting(true);
    setCurrentStep(1);
    setStepName('SLIDES');
  };

  // Initial loading
  useEffect(() => {
    const load = async () => {
      if (!hasConfirmed && !(await attempt(true))) return;

      setStepName('LOADED_INITIAL_DATA');
      setCurrentStep(1);
    };

    if (isOpen && !isLoading && stepName === 'LOADING' && dataRepcard?.repCard) {
      console.log('Start loading...');
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, stepName, dataRepcard, hasConfirmed]);

  // Just after initial loading
  useEffect(() => {
    if (stepName === 'LOADED_INITIAL_DATA') {
      console.log('Loaded initial data...');

      if (dataRepcard?.repCard.type === 'GALLERY') {
        setStepName('GALLERY');
        return;
      }
      if (dataRepcard?.repCard.type === 'SOCIAL_SHARE') {
        setStepName('SOCIAL_SHARE');
        return;
      }
      if (dataRepcard?.repCard.type == 'PRODUCT_PURCHASE') {
        redirectToStore(dataRepcard?.repCard);
        setStepName('PRODUCT_PURCHASE');
        return;
      }
      if (dataRepcard?.repCard.type == 'INSTANT_GRATIFICATION') {
        if (initialHasCompleted || isCompleted) {
          //onClose();
        } else {
          sendAnswer([]);
        }
        return;
      }

      if (dataRepcard?.repCard.type === 'SURVEY') {
        if (isCompleted) {
          //onClose();
        } else {
          setStepName('SLIDES');
        }
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepName]);

  // Handle completed repcards behaviour
  useEffect(() => {
    const DO_NOT_SHOW_UNLOCK: Array<StepNameType> = ['GALLERY', 'RESULTS'];
    // If the user has completed the repcard, show only the unlock screen when the user has
    // just completed the rep card, to show always remove the !initialHasCompleted
    if (
      (isRewarded || isSimulation) &&
      isCompleted &&
      !repeatParticipations &&
      rewardMetadata &&
      !initialHasCompleted &&
      !DO_NOT_SHOW_UNLOCK.includes(stepName)
    ) {
      setStepName('UNLOCK');
      setIsDark(true);
      return;
    } else if (isRewarded && isCompleted && !initialHasCompleted && !isSimulation && rewardMetadata) {
      const mPartibleParams = MAP_REP_REWARD_TYPE_TO_MPARTICLE_EVENT[dataRepcard?.repCard.reward.type];
      if (mPartibleParams) {
        const { event, params } = mPartibleParams(dataRepcard, rewardMetadata);
        logMparticleEvent(event, mParticle.EventType.Other, params);
      }
    }

    if (isCompleted && (stepName == 'LOADED_INITIAL_DATA' || stepName == 'SLIDES')) {
      onComplete();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted, stepName]);

  const onComplete = async () => {
    const repcardType = dataRepcard?.repCard?.type;
    setIsDark(false);

    if (repcardType == 'SOCIAL_SHARE') {
      return;
    }

    if (repcardType === 'GALLERY') {
      return setStepName('GALLERY');
    }

    if (repcardType === 'SURVEY') {
      return setStepName('RESULTS');
    }

    return showButtonOnFinish && onPressButtonOnFinish ? onPressButtonOnFinish() : onClose('COMPLETED', true);
  };

  const sendAnswer = async (slides: IAddRepcardParticipationVars['slides']) => {
    if (!isSimulation) {
      const completeParticipation = !totalSteps || totalSteps == currentStep;

      await addRepcardParticipation({
        variables: {
          repCardId: parseInt(id),
          slides,
          completeParticipation,
        },
      });

      if (completeParticipation) {
        setIsRestarting(false);
      }

      await refetchRepcard();
    }

    setCurrentStep(currentStep + 1);
  };

  const redirectToStore = async (data: RepCard) => {
    if (data.repCardPurchase.storeUrl) {
      window.location.href = data.repCardPurchase.storeUrl;
    } else {
      const product = data.repCardPurchase?.ecommProduct;

      if (product) {
        if (product.product_shop_url) {
          console.log(product.product_shop_url);
          const url = product.product_shop_url.includes('https://')
            ? product.product_shop_url
            : 'https://' + product.product_shop_url;
          window.location.href = url;
        }
      }
    }

    onClose('REDIRECT_TO_STORE', false);
  };

  useEffect(() => {
    if (stepName === 'UNLOCK' && dataRepcard) {
      const mPartibleParams = MAP_REP_REWARD_TYPE_TO_MPARTICLE_EVENT[dataRepcard.repCard.reward.type];
      if (mPartibleParams && !isSimulation) {
        const { event, params } = mPartibleParams(dataRepcard, rewardMetadata);
        logMparticleEvent(event, mParticle.EventType.Other, params);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepName, dataRepcard]);

  useEffect(() => {
    const shouldResubmit = requiresApproval && canResubmit && !hasApprovedParticipation;
    if (!!dataRepcard?.repCard?.type && initialHasCompleted && !shouldResubmit && stepName == 'LOADED_INITIAL_DATA') {
      onComplete();
    }
  }, [initialHasCompleted, dataRepcard?.repCard?.type, stepName]);

  const verifyCompletionAndClose = useCallback(() => {
    if (isCompleted && !initialHasCompleted) {
      onClose('COMPLETED', true);
    } else {
      onClose(initialHasCompleted ? 'CLOSE' : 'DISMISS', false);
    }
  }, [isCompleted, onClose, initialHasCompleted]);

  const exitButton = () => (
    <Flex
      data-testid="close-challenge"
      __css={{ cursor: 'pointer', 'pointer-events': 'auto' }}
      justifyContent="center"
      onClick={verifyCompletionAndClose}
      color={isDark ? 'white' : 'black'}
      zIndex={1}
    >
      <Icon.BoxiconsRegular.X size={30} />
    </Flex>
  );

  const getBoxCSS = (fullScreen, name) => {
    const STATE_WIDTH = {
      GALLERY: '350px',
      UNLOCK: '800px',
    };

    const padding = ['GALLERY', 'RESULTS', 'UNLOCK'].includes(name) ? '0px' : '14px';
    const baseCSS = {
      height: '100%',
      padding,
    };

    if (fullScreen) {
      return {
        ...baseCSS,
        width: '100%',
      };
    }

    return {
      ...baseCSS,
      width: STATE_WIDTH[name] || '420px',
    };
  };

  return (
    <>
      <ContentModal
        isOpen={isOpen}
        fullScreen={fullScreen}
        shouldCloseOnOverlayClick
        onRequestClose={verifyCompletionAndClose}
        body={
          <Flex flexDirection="column" width="100%">
            <Flex
              flexDirection="column"
              __css={getBoxCSS(fullScreen, stepName)}
              bg={isDark ? 'neutrals-900' : 'white'}
              height="100%"
            >
              {['LOADING', 'LOADED_INITIAL_DATA', 'PRODUCT_PURCHASE'].includes(stepName) ? (
                stepName == 'PRODUCT_PURCHASE' ? (
                  <Text>Redirecting to the product shopping page...</Text>
                ) : (
                  <Loading />
                )
              ) : (
                <>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    p="8px"
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                  >
                    <Flex flex={1} alignItems="center" justifyContent="center">
                      {stepName == 'SLIDES' && currentStep <= totalSteps && (
                        <Progress percentage={(currentStep / totalSteps) * 100} />
                      )}
                    </Flex>

                    {['GALLERY', 'RESULTS', 'SLIDES', 'SOCIAL_SHARE'].includes(stepName) && <>{exitButton()}</>}
                  </Flex>
                  <Flex width="100%" height="100%" flexDirection="column">
                    {stepName == 'SLIDES' && (
                      <Box mt={5}>
                        <StepWizard step={currentStep - 1}>
                          {dataRepcard?.repCard.showReferenceImages && (
                            <ReferenceSlide
                              onSubmit={() => {
                                setCurrentStep(currentStep + 1);
                              }}
                              showBorders={false}
                              name={dataRepcard.repCard.prompt}
                              description={dataRepcard.repCard.referenceImagesDescription}
                              referenceImagesUrls={dataRepcard.repCard.referenceImagesUrls}
                            />
                          )}

                          {dataRepcard?.repCard.slides.map((slide) => (
                            <Box key={slide.id}>
                              {slide.type === 'MULTIPLE_CHOICE' && (
                                <MultipleChoiceSlide
                                  key={slide.id}
                                  showBorders={false}
                                  name={slide.questionTitle}
                                  options={slide.multipleChoiceOptions}
                                  loading={loadingAddParticipation}
                                  onSubmit={(selectedOption) =>
                                    sendAnswer([
                                      {
                                        slideId: slide.id,
                                        choiceOptionId: selectedOption,
                                      },
                                    ])
                                  }
                                />
                              )}
                              {slide.type === 'TEXT_RESPONSE' && (
                                <TextResponseSlide
                                  key={slide.id}
                                  showBorders={false}
                                  name={slide.questionTitle}
                                  loading={loadingAddParticipation}
                                  onSubmit={(text) =>
                                    sendAnswer([
                                      {
                                        slideId: slide.id,
                                        answerText: text,
                                      },
                                    ])
                                  }
                                />
                              )}
                              {slide.type === 'MEDIA_UPLOAD' && (
                                <MediaUploadSlide
                                  key={slide.id}
                                  showBorders={false}
                                  name={slide.questionTitle}
                                  loading={loadingAddParticipation}
                                  onSubmit={(uploadedUrl, caption) =>
                                    sendAnswer([
                                      {
                                        slideId: slide.id,
                                        answerMediaUrl: uploadedUrl,
                                        answerText: caption,
                                      },
                                    ])
                                  }
                                />
                              )}
                            </Box>
                          ))}
                        </StepWizard>
                      </Box>
                    )}

                    {stepName === 'GALLERY' && dataRepcard?.repCard && (
                      <GalleryViewModal
                        brand={dataRepcard.repCard.brand}
                        hasCompleted={initialHasCompleted}
                        isSimulation={isSimulation}
                        repCard={dataRepcard.repCard}
                        rewardMetadata={rewardMetadata}
                        refetchRepcard={(variables) => refetchRepcard(variables)}
                      />
                    )}

                    {stepName === 'SOCIAL_SHARE' && dataRepcard?.repCard && (
                      <SocialShareModal
                        repCard={dataRepcard.repCard}
                        refetchRepcard={(variables) => refetchRepcard(variables)}
                        rewardMetadata={rewardMetadata}
                        onClose={() => onClose('CLOSE')}
                      />
                    )}

                    {stepName === 'RESULTS' && dataRepcard?.repCard && (
                      <RepCardResultsModal
                        repCard={dataRepcard?.repCard}
                        isSimulation={isSimulation}
                        onReSubmit={() => restartSurvey()}
                      />
                    )}

                    {stepName === 'UNLOCK' && dataRepcard?.repCard && (
                      <UnlockRewardModalContent
                        rewardMetadata={rewardMetadata}
                        reward={dataRepcard?.repCard?.reward}
                        onContinue={onComplete}
                      />
                    )}
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        }
      />
      {modal}
    </>
  );
};

export default CompleteRepCardModal;
