import { RepCardRewardMetadata, RepCardRewardTypes } from '../../types';
import { RepCardData } from './graphql/queries/getRepCard';

export const MAP_REP_REWARD_TYPE_TO_MPARTICLE_EVENT: {
  [key in RepCardRewardTypes]: (
    repcardData: RepCardData,
    metadata?: RepCardRewardMetadata
  ) => {
    event: string;
    params: { [key: string]: string | boolean | number };
  } | null;
} = {
  [RepCardRewardTypes.COLLECTIBLE]: (repcardData: RepCardData, metadata: RepCardRewardMetadata) => {
    return {
      event: 'earn_collectible',
      params: {
        brand_uuid: repcardData.repCard.brand.uuid,
        brand_name: repcardData.repCard.brand.name.toLowerCase(),
        amount: repcardData.repCard.reward.quantity,
        collectible_contract_address: repcardData.repCard.reward.contractAddress,
        collectible_contract_token_id: repcardData.repCard.reward.assetId,
        collectible_name: metadata?.name.toLowerCase(),
        collectible_type: repcardData.repCard.reward.type.toLowerCase(),
      },
    };
  },
  [RepCardRewardTypes.BRAND_COIN]: (repcardData: RepCardData, metadata: RepCardRewardMetadata) => {
    return {
      event: 'earn_coins',
      params: {
        brand_uuid: repcardData.repCard.brand.uuid,
        brand_name: repcardData.repCard.brand.name.toLowerCase(),
        amount: repcardData.repCard.reward.quantity,
        collectible_contract_address: repcardData.repCard.reward.contractAddress,
        collectible_contract_token_id: repcardData.repCard.reward.assetId,
        collectible_name: metadata.name.toLowerCase(),
        collectible_type: repcardData.repCard.reward.type.toLowerCase(),
      },
    };
  },
  [RepCardRewardTypes.REPS_ONLY]: null,
  [RepCardRewardTypes.CHANNEL_ACCESS]: null,
};
