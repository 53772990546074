import { gql } from '@apollo/client';

import { ISocialShareUser } from '../../types';

export interface GetSocialUserVars {
  platform: string;
  brandUuid: string;
}

export interface GetSocialUserData {
  socialUser: ISocialShareUser;
}

export const GET_SOCIAL_USER = gql`
  query SocialUser($brandUuid: String!, $platform: String!) {
    socialUser(brandUuid: $brandUuid, platform: $platform) {
      pk
      username
      name
      profilePictureUrl
    }
  }
`;
