import { Box, Button, Flex, Grid, Text } from '@tyb-u/tyb-ui-components';
import React, { useState } from 'react';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from 'src/utils';
import { useTheme } from 'styled-components';

import { IMultipleChoiceOption, MultipleChoiceOptionType } from '../../../types';
import ImageOption from '../Components/ImageOption';
import SlideTitle from '../Components/TitleSlide';

interface MultipleChoiceSlideProps {
  name: string;
  showBorders?: boolean;
  onSubmit?: (selectedOption: number) => void;
  options: IMultipleChoiceOption[];
  loading?: boolean;
}

type SlidePreviewProps = {
  index: number;
  option: IMultipleChoiceOption;
  selectedOption: number;
  setSelectedOption: (option: number) => void;
};

const TextSlide: React.FC<SlidePreviewProps> = ({ option, selectedOption, setSelectedOption }) => (
  <Box
    data-testid="challenge-multiple-text-choice-slide"
    width="100%"
    height={56}
    key={option.id}
    onClick={() => setSelectedOption(option.id)}
    color={selectedOption === option.id ? 'white' : 'black'}
    bg={selectedOption === option.id ? 'black' : 'white'}
    py="2"
    px="4"
    border="dark"
    my="2"
    borderRadius="48px"
    display="table"
    __css={{ cursor: 'pointer' }}
  >
    <Text
      variant="text5-600"
      style={{
        verticalAlign: 'middle',
        display: 'table-cell',
      }}
      color={selectedOption === option.id ? 'white' : 'neutrals-900'}
    >
      {option.value || option.title}
    </Text>
  </Box>
);

const MultipleChoiceSlide: React.FC<MultipleChoiceSlideProps> = ({
  name,
  showBorders = true,
  onSubmit,
  options,
  loading = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const type: MultipleChoiceOptionType = options[0]?.type;

  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  return (
    <Box
      border={showBorders ? 'thin' : 'none'}
      borderRadius="md"
      overflow="hidden"
      bg="white"
      pt="2"
      width="100%"
      p="2"
    >
      <Flex flexDirection="column" p="2" justifyContent="space-between">
        <Flex flex={1}>
          <SlideTitle value={name} />
        </Flex>
      </Flex>
      <Box width="100%" pb={[75, 'unset']} maxHeight={['unset', '60vh']} overflowY={['unset', 'auto']}>
        {type === 'TEXT_CHOICE' && (
          <Flex flexWrap="wrap">
            {options.map((option, index) => {
              if (option.type === 'TEXT_CHOICE') {
                return (
                  <TextSlide
                    key={index}
                    index={index}
                    option={option}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                );
              }
            })}
          </Flex>
        )}
        {type === 'IMAGE_CHOICE' && (
          <Grid gridTemplateColumns="repeat(auto-fill, minmax(140px, 1fr))" gridGap="2">
            {options.map((option, index) => {
              if (option.type === 'IMAGE_CHOICE') {
                return (
                  <ImageOption
                    key={index}
                    index={index}
                    option={option}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                );
              }
            })}
          </Grid>
        )}
      </Box>

      <Flex
        style={
          isMobile
            ? {
                padding: '20px',
                position: 'fixed',
                bottom: '0',
                backgroundColor: 'white',
                width: '100%',
                marginLeft: '-25px',
                marginRight: '15px',
              }
            : {
                marginTop: '20px',
              }
        }
      >
        <Button disabled={selectedOption === null || loading} width="100%" onClick={() => onSubmit(selectedOption)}>
          {loading ? 'Loading...' : 'Continue'}
        </Button>
      </Flex>
    </Box>
  );
};

export default MultipleChoiceSlide;
