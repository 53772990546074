import { Box, Button, Flex, Input, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useRef, useState } from 'react';
import { getSupportedMediaMimeTypes } from 'src/utils/file';

import FileUploader from '../../../components/FileUploader/FileUploader';
import { MultipartUploaderRef } from '../../../containers/MultipartUploader/MultipartUploader';
import { GalleryViewProps } from '../GalleryViewModal';
import GalleryReward from './GalleryReward';

type GalleryViewFooterProps = Pick<GalleryViewProps, 'rewardMetadata' | 'repCard'> & {
  showUnlockMessage: boolean;
  loading: boolean;
  onSubmit: (uploadedUrl: string, caption: string) => void;
};

export const GalleryViewFooter: React.FC<GalleryViewFooterProps> = ({
  showUnlockMessage,
  repCard,
  rewardMetadata,
  loading,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [isDisabled, setIsDisabled] = useState(true);
  const [caption, setCaption] = useState<string>(undefined);
  const multipartUploaderRef = useRef<MultipartUploaderRef>();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const uploadedUrl = await multipartUploaderRef.current.upload();
    onSubmit(uploadedUrl, caption);
    setIsLoading(false);
  }, [multipartUploaderRef, caption]);

  const supportedMimeTypes = getSupportedMediaMimeTypes();

  return (
    <Flex flexDirection="column" position="relative" justifyContent="center" bg="white" __css={{ gap: '15px' }}>
      <FileUploader
        accept={supportedMimeTypes}
        ref={multipartUploaderRef}
        folder="repcards-respons"
        maxFileSize={256}
        onChange={() => setIsDisabled(false)}
      />
      <Box mt={2}>
        <Input
          as="textarea"
          placeholder="Describe your upload or add a link..."
          name="response-answer"
          height={88}
          value={caption}
          disabled={isLoading}
          onChange={(e) => setCaption(e.target.value)}
        />
        <Flex flexDirection={'row'} justifyContent={'end'} alignItems={'center'}>
          <Text
            variant="text6-400"
            textAlign={'end'}
            color={caption?.length >= 141 ? '#DF2B00' : caption?.length >= 130 ? '#EEA432' : undefined}
          >
            {caption?.length | 0}/140
          </Text>
        </Flex>
      </Box>
      {showUnlockMessage && (
        <Text variant="text5-400" mt="1">
          To unlock this gallery, complete this repcard...
        </Text>
      )}
      <Button
        width="100%"
        disabled={isDisabled || isLoading || loading || caption?.length > 140}
        onClick={handleSubmit}
      >
        {isLoading ? 'Submitting...' : 'Submit Photo'}
      </Button>

      <GalleryReward
        rewardQuantity={repCard.reward.quantity}
        rewardType={repCard.reward.type}
        rewardMetadata={rewardMetadata}
        showMedia={false}
      />
    </Flex>
  );
};
