import { Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import { useEffect, useState } from 'react';

import OauthPopup from '../../../../components/OauthPopup';
import useSocialAuth from '../../../../hooks/useSocialAuth';

type StatusType = 'connected' | 'disconnected' | 'loading';

type TikTokAuthProps = {
  brandUuid: string;
  hideOnConnected?: boolean;
  onChange?: (status: StatusType, isNewSession: boolean) => void;
};

const TikTokAuth: React.FC<TikTokAuthProps> = ({ brandUuid, hideOnConnected, onChange = () => null }) => {
  const [status, setStatus] = useState<StatusType>('loading');
  const [name, setName] = useState<string>();
  const [url, setUrl] = useState('');
  const [isNewSession, setIsNewSession] = useState<boolean>(false);

  const { saveSocialAuthToken, verifyToken, getSocialUser, getSocialAuthLink } = useSocialAuth();

  const onCode = async (code: string) => {
    await saveSocialAuthToken({
      variables: {
        brandUuid,
        platform: 'TIK_TOK',
        token: decodeURIComponent(code.toString()),
      },
    });

    setIsNewSession(true);
    setStatus('connected');
  };

  const onClose = () => console.log('closed!');

  useEffect(() => {
    onChange(status, isNewSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    verifyToken('TIK_TOK', brandUuid).then((res) => {
      if (res.verifySocialAuthToken) {
        getSocialUser({
          platform: 'TIK_TOK',
          brandUuid,
        }).then((res) => {
          if (res?.data?.socialUser) {
            setName(res.data.socialUser.name || res.data.socialUser.username);
            setStatus('connected');
          }
        });
      } else {
        getSocialAuthLink({
          platform: 'TIK_TOK',
          brandUuid,
        }).then((res) => {
          setUrl(res.data.socialAuthLink);
          setStatus('disconnected');
        });
      }
    });
  }, []);

  if (hideOnConnected && status === 'connected') return null;

  return (
    <OauthPopup url={url} title="TikTok" height={700} width={500} onClose={onClose} onCode={onCode}>
      <Flex
        flexDirection="row"
        bg="black"
        color="white"
        justifyContent="center"
        alignItems="center"
        width="100%"
        fontSize={14}
        __css={{ cursor: url && 'pointer', userSelect: 'none', borderRadius: '8px', px: '16px', py: '4px' }}
      >
        {status === 'loading' && 'Loading...'}
        {status === 'connected' && (
          <Text variant="text4-400" color="white">
            {name ? `Connected as ${name}` : 'Connected'}
          </Text>
        )}
        {status === 'disconnected' && (
          <>
            <Icon.BoxiconsLogos.Tiktok size={22} />
            <Text as="p" ml="2" color="white">
              Continue with TikTok
            </Text>
          </>
        )}
      </Flex>
    </OauthPopup>
  );
};

export default TikTokAuth;
