import { gql } from '@apollo/client';

export interface verifyBrandParticipationRequirementsVars {
  brandUuid: string;
}

export interface verifyBrandParticipationRequirementsData {
  verifyBrandParticipationRequirements: {
    needAgeVerification: string;
    needAgeVerificationData: {
      minParticipationAge: number;
      userAge: number;
    };
  };
}

export const VERIFY_BRAND_PARTICIPATION_REQUIREMENTS = gql`
  query verifyBrandParticipationRequirements($brandUuid: String!) {
    verifyBrandParticipationRequirements(brandUuid: $brandUuid) {
      needAgeVerification
      needAgeVerificationData {
        minParticipationAge
        userAge
      }
    }
  }
`;
