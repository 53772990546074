import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useEffect, useState } from 'react';
import { IUser } from 'src/interface/IUser';

import FacebookLogin from '../../../../components/FacebookLogin';
import Loading from '../../../../components/Loading';
import TeamMembersPreview from '../../../../components/TeamMembersPreview';
import { RepCardRewardMetadata } from '../../../../types';
import GalleryReward from '../../../GalleryViewModal/components/GalleryReward';
import { RepCardData } from '../../../RepCardCompleteModal/graphql/queries/getRepCard';
import { ISocialShareInstructionsState } from '../../SocialShareModal';
import TikTokAuth from '../TikTokAuth/TikTokAuth';

interface SocialConnectionsProps {
  repCard: RepCardData['repCard'];
  rewardMetadata?: RepCardRewardMetadata;
  instruction: ISocialShareInstructionsState;
  onInit: (closeRepCard?: boolean) => void;
  participants: IUser[];
  participationsCount: number;
}

const SocialConnections: React.FC<SocialConnectionsProps> = ({
  repCard,
  rewardMetadata,
  instruction,
  onInit,
  participants = [],
  participationsCount = 0,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState(false);

  const proceedSocialShare = useCallback(() => {
    onInit(true);
  }, [instruction]);

  const handleSocialAuthChange = useCallback((status, isNewSession) => {
    setIsConnected(status === 'connected');

    if (status === 'connected' || isNewSession) {
      onInit();
    }
  }, []);

  useEffect(() => {
    if (instruction) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instruction]);

  if (loading) return <Loading />;

  return (
    <Box>
      <Box pb="2">
        <Text variant="text3-400" mb="2">
          {repCard.prompt}
        </Text>
        <Text>{instruction.instruction}</Text>
        <Box mt="1" mb="1">
          <Text variant="text5-400" color="gray04" p="0">
            To complete this challenge, tag or mention the following in your post:
            <Text as="span" variant="text5-400" color="orange" pl="1">
              {instruction.tags.map((m) => `@${m}`).join(', ') +
                (instruction.hashtags.length && instruction.tags.length ? ', ' : '') +
                instruction.hashtags.map((m) => `#${m}`).join(', ')}
            </Text>
          </Text>
        </Box>
      </Box>

      <Flex flexDirection="row" justifyContent="space-between" py="1">
        {participants.length > 0 && participationsCount > 0 && (
          <TeamMembersPreview
            brandUuid={repCard.brand.uuid}
            brandName={repCard.brand.name}
            teamMembers={participants.map((item) => ({
              id: item.id.toString(),
              avatar: item.avatarUrl,
            }))}
            max={5}
            total={participationsCount}
            itemWidth={36}
            showTitle={false}
            staticVersion={true}
          />
        )}
        <GalleryReward
          rewardType={repCard.reward.type}
          rewardMetadata={rewardMetadata}
          rewardQuantity={repCard.reward.quantity}
          small
          containerStyle={
            participants.length > 0 && participationsCount > 0
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'flex-start' }
          }
        />
      </Flex>

      <Flex flexDirection="column" py="2" mt="2">
        {instruction.platform === 'INSTAGRAM' && (
          <FacebookLogin brandUuid={repCard.brand.uuid} onChange={handleSocialAuthChange} hideOnConnected />
        )}
        {instruction.platform === 'TIK_TOK' && (
          <TikTokAuth brandUuid={repCard.brand.uuid} onChange={handleSocialAuthChange} hideOnConnected />
        )}

        {isConnected && (
          <Flex flexDirection="column">
            <Text variant="text3-400">
              {`Thanks for connecting your ${
                instruction.platform === 'INSTAGRAM' ? 'Instagram' : 'TikTok'
              } account! Here's how it works:`}
            </Text>
            <Box px={5} mb={5}>
              <ul>
                <li>
                  <Text variant="text4-400">
                    {`We'll check your TikTok periodically for posts that match the challenge criteria.`}
                  </Text>
                </li>
                <li>
                  <Text variant="text4-400">{`When your post matches the criteria, you'll get rewarded.`}</Text>
                </li>
                <li>
                  <Text variant="text4-400">{`No need to check back here for progress, we'll send you a notification email to confirm your reward!`}</Text>
                </li>
              </ul>
            </Box>

            <Button variant="primary" mt="2" onClick={proceedSocialShare}>
              Sounds good!
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default SocialConnections;
