import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import Loading from '../../components/Loading';
import PusherConsumer from '../../components/PusherConsumer';
import {
  ADD_REPCARD_PARTICIPATION,
  IAddRepcardParticipationData,
  IAddRepcardParticipationVars,
} from '../../graphql/mutations/addRepcardParticipation';
import { ISocialPlatforms, RepCardRewardMetadata } from '../../types';
import { RepCardData, RepCardVars } from '../RepCardCompleteModal/graphql/queries/getRepCard';
import SocialConnections from './components/SocialConnections/SocialConnections';
import { useRepcardSocialShareInstructions } from './hooks/useRepcardSocialShareInstructions';

interface ISocialShareProps {
  repCard: RepCardData['repCard'];
  rewardMetadata?: RepCardRewardMetadata;
  refetchRepcard: (variables?: RepCardVars) => Promise<ApolloQueryResult<RepCardData>>;
  onClose?: () => void;
}

export interface ISocialShareInstructionsState {
  platformId: number;
  platform: ISocialPlatforms;
  hasHashtags: boolean;
  hashtags: string[];
  instruction: string;
  hasTags: boolean;
  tags: string[];
}

const SocialShare: React.FC<ISocialShareProps> = ({
  repCard,
  rewardMetadata,
  refetchRepcard,
  onClose = () => null,
}) => {
  const { data, loading } = useRepcardSocialShareInstructions(parseInt(repCard.id));
  const [instructions, setInstructions] = useState<ISocialShareInstructionsState>(null);

  const [addRepcardParticipation] = useMutation<IAddRepcardParticipationData, IAddRepcardParticipationVars>(
    ADD_REPCARD_PARTICIPATION
  );

  const handleInitParticipation = useCallback(async (closeRepCard?: boolean) => {
    // Schedule api call to run even if the component is unmounted
    setTimeout(async () => {
      await addRepcardParticipation({
        variables: {
          repCardId: Number(repCard.id),
          slides: [],
          completeParticipation: false,
        },
      });
    }, 100);

    if (closeRepCard) {
      onClose();
    } else {
      await refetchRepcard();
    }
  }, []);

  const handleCompleteParticipation = useCallback(async () => {
    await refetchRepcard();
  }, [refetchRepcard]);

  useEffect(() => {
    if (data && !loading) {
      setInstructions({
        ...data.repCard.socialShareInstructions,
        hasHashtags: !!data.repCard.socialShareInstructions.hashtags.length,
        hasTags: !!data.repCard.socialShareInstructions.tags.length,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  if (loading) return <Loading />;

  return (
    <>
      <PusherConsumer event="repcard.completed" onData={handleCompleteParticipation} />

      <SocialConnections
        repCard={repCard}
        rewardMetadata={rewardMetadata}
        instruction={instructions}
        participants={data?.repCard?.participantUsersHighlight || []}
        participationsCount={data?.repCard?.participationCount || 0}
        onInit={handleInitParticipation}
      />
    </>
  );
};

export default SocialShare;
