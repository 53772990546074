import { gql } from '@apollo/client';

import { IBrandEcommProduct } from '../../../../interface/IBrandEcommProduct';
import {
  IBrandGate,
  IInteractivity,
  IMultipleChoiceOption,
  IRepcardConsolidatedParticipation,
  RepCardParticipationApprovalStatus,
  RepCardParticipationStatusType,
  RepcardStatusTypes,
  RepCardTypes,
  RewardTypes,
  SlideTypes,
  VisibilityTypes,
} from '../../../../types';

export interface RepCardRewardData {
  type: RewardTypes;
  reps?: number;
  assetId?: string;
  quantity?: number;
  contractAddress?: string;
  requiresApproval?: boolean;
  canResubmit?: boolean;
}

export interface RepcardParticipation {
  id: number;
  repCardId: number;
  userId: number;
  status: RepCardParticipationStatusType;
  rewarded: boolean;
  approval?: RepcardParticipationApproval | null;
}

export interface RepcardParticipationApproval {
  status?: RepCardParticipationApprovalStatus | null;
  message?: string;
}

export interface RepCard {
  id: string;
  uuid: string;
  brandId: number;
  brand: {
    id: number;
    uuid: string;
    name: string;
    avatarUrl: string;
    isMember: boolean;
    urlSlug: string;
    minParticipationAge?: number;
  };
  prompt: string;
  type: RepCardTypes;
  timeLimit: string;
  coverImageUrl: string | null;
  hideCollectibleResponses?: boolean;
  // channelId: string | number;
  showReferenceImages: boolean;
  referenceImagesDescription: string;
  referenceImagesUrls: string[];
  showSurveyResults: boolean;
  repeatParticipations: boolean;
  reward: RepCardRewardData;
  interactivity: IInteractivity;
  visibility: VisibilityTypes;
  slides: {
    id: number;
    type: SlideTypes;
    questionTitle: string;
    position: number;
    __typename: string;
    multipleChoiceOptions: IMultipleChoiceOption[];
  }[];
  repCardPurchase: {
    id: string;
    productId: string;
    storeUrl: string;
    ecommProduct: IBrandEcommProduct;
  } | null;
  gates: IBrandGate[];
  status: RepcardStatusTypes;
  createdAt: string;
  maxParticipations?: number;
  participationCount?: number;
  myConsolidatedParticipation?: IRepcardConsolidatedParticipation;
}

export interface RepCardData {
  repCard: RepCard;
}

export interface RepCardVars {
  id: number;
}

export const GET_REP_CARD = gql`
  query repCard($id: Int!) {
    repCard: repCard(id: $id) {
      id
      uuid
      brandId
      brand {
        id
        uuid
        name
        avatarUrl
        isMember
        urlSlug
        minParticipationAge
      }
      prompt
      type
      timeLimit
      coverImageUrl
      visibility
      showReferenceImages
      hideCollectibleResponses
      referenceImagesDescription
      referenceImagesUrls
      showSurveyResults
      repeatParticipations
      reward {
        type
        reps
        assetId
        quantity
        contractAddress
        requiresApproval
        canResubmit
      }
      slides {
        id
        type
        questionTitle
        position
        multipleChoiceOptions {
          id
          title
          type
          imageUrl
        }
      }
      repCardPurchase {
        id
        productId
        storeUrl
        ecommProduct {
          id
          title
          product_shop_url
        }
      }
      interactivity {
        totalComments
        totalHi5s
        uuid
      }
      gates {
        contractAddress
        id
        tokenId
        quantity
        sourceType
        updatedAt
        uuid
      }
      status
      createdAt
      maxParticipations
      participationCount
      myConsolidatedParticipation {
        status
        hasBeenApproved
        hasBeenRejected
        total
        totalPending
        totalRewarded
      }
    }
  }
`;
