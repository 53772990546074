import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
  CREATE_COMMENT,
  CreateCommentData,
  DELETE_COMMENT,
  SEARCH_COMMENTS,
  SEARCH_RECENT_COMMENTS,
  SearchCommentsData,
  SearchRecentCommentsData,
  SearchRecentCommentsVars,
} from '../../graphql/queries/comments';
import { InteractivitySourceType } from '../../types';

export interface useCommentsProps {
  type: InteractivitySourceType;
  uuid: string;
}

const useComments = (variables: useCommentsProps) => {
  const listQuery = useQuery<SearchCommentsData, unknown>(SEARCH_COMMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      type: variables.type,
      uuid: variables.uuid,
      skip: 0,
      take: 10,
    },
  });

  const listRecentCommentsQuery = useLazyQuery<SearchRecentCommentsData, SearchRecentCommentsVars>(
    SEARCH_RECENT_COMMENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        type: variables.type,
        uuid: variables.uuid,
        take: 20,
        referenceUuid: null,
      },
    }
  );

  const [createMutation] = useMutation<CreateCommentData, unknown>(CREATE_COMMENT);

  const [deleteMutation] = useMutation<any, unknown>(DELETE_COMMENT);

  const createComment = useCallback(
    async (text: string) => {
      try {
        return await createMutation({ variables: { type: variables.type, uuid: variables.uuid, text } });
        //return await listQuery.refetch();
      } catch (ex) {
        console.error(ex);
      }
    },
    [createMutation, listQuery, variables]
  );

  const deleteComment = useCallback(
    async (uuid: string) => {
      try {
        await deleteMutation({ variables: { uuid } });
        await listQuery.refetch();
      } catch (ex) {
        console.error(ex);
      }
    },
    [deleteMutation, listQuery]
  );

  return {
    listQuery,
    listRecentCommentsQuery,
    create: createComment,
    deleteComment: deleteComment,
  };
};

export default useComments;
