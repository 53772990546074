import { Button, Icon, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useEffect, useState } from 'react';

import useSocialAuth from '../../hooks/useSocialAuth';

type StatusType = 'connected' | 'disconnected' | 'loading';

type FacebookLoginProps = {
  brandUuid: string;
  hideOnConnected?: boolean;
  onChange?: (status: StatusType, isNewSession: boolean) => void;
};

const FacebookLogin: React.FC<FacebookLoginProps> = ({ brandUuid, hideOnConnected, onChange = () => null }) => {
  const [status, setStatus] = useState<StatusType>('loading');
  const [name, setName] = useState<string>();
  const [isNewSession, setIsNewSession] = useState<boolean>(false);

  const { saveSocialAuthToken, verifyToken, getSocialUser } = useSocialAuth();

  const requestInfo = useCallback(async () => {
    const res = await getSocialUser({
      platform: 'INSTAGRAM',
      brandUuid,
    });

    if (res.data?.socialUser) {
      setName(res.data.socialUser.name || res.data.socialUser.username);
    }

    setStatus('connected');
  }, []);

  const handleLogin = useCallback((isExpired = false) => {
    FB.login(
      async (res) => {
        if (res.status === 'connected') {
          await saveSocialAuthToken({
            variables: {
              platform: 'INSTAGRAM',
              brandUuid,
              token: res.authResponse.accessToken,
            },
          });

          setIsNewSession(true);
          await requestInfo();
        }
      },
      {
        scope: 'instagram_basic,instagram_manage_insights,pages_show_list',
        auth_type: isExpired ? 'reauthorize' : undefined,
      }
    );
  }, []);

  useEffect(() => {
    onChange(status, isNewSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const init = useCallback(async () => {
    const { verifySocialAuthToken } = await verifyToken('INSTAGRAM', brandUuid);
    if (!verifySocialAuthToken) {
      setStatus('disconnected');
    } else {
      setStatus('connected');
      await requestInfo();
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  if (hideOnConnected && status === 'connected') return null;

  return (
    <Button
      variant="primary"
      disabled={status === 'loading'}
      width="100%"
      onClick={() => handleLogin()}
      __css={{ borderRadius: '8px', px: '16px', py: '4px' }}
    >
      {status === 'loading' && 'Loading...'}
      {status === 'connected' && <Text variant="text4-400">{name ? `Connected as ${name}` : 'Connected'}</Text>}
      {status === 'disconnected' && (
        <>
          <Icon.BoxiconsLogos.FacebookCircle size={22} />
          <Text as="p" ml="2">
            Continue with Facebook
          </Text>
        </>
      )}
    </Button>
  );
};

export default FacebookLogin;
