import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import React, { useCallback, useState } from 'react';

import ImageCDN from '../../components/ImageCDN';
import TitleSlide from '../../components/RepCard/Components/TitleSlide';
import {
  ADD_REPCARD_PARTICIPATION,
  IAddRepcardParticipationData,
  IAddRepcardParticipationVars,
} from '../../graphql/mutations/addRepcardParticipation';
import { RepCardRewardMetadata } from '../../types';
import { RepCardData, RepCardVars } from '../RepCardCompleteModal/graphql/queries/getRepCard';
import GalleryReward from './components/GalleryReward';
import { GalleryViewFooter } from './components/GalleryViewFooter';

export type GalleryViewProps = {
  repCard: RepCardData['repCard'];
  rewardMetadata?: RepCardRewardMetadata;
  brand: {
    name: string;
    avatarUrl: string;
  };
  isSimulation?: boolean;
  hasCompleted?: boolean;
  refetchRepcard: (variables?: RepCardVars) => Promise<ApolloQueryResult<RepCardData>>;
};

const GalleryViewModal: React.FC<GalleryViewProps> = ({
  repCard,
  rewardMetadata,
  isSimulation,
  hasCompleted: intialHasCompleted,
  refetchRepcard,
}) => {
  const [isLoadingParticipation, setIsLoadingParticipation] = useState<boolean>(false);
  const [hasCompleted, setHasCompleted] = useState<boolean>(intialHasCompleted);

  const [addRepcardParticipation] = useMutation<IAddRepcardParticipationData, IAddRepcardParticipationVars>(
    ADD_REPCARD_PARTICIPATION
  );

  const handleSubmit = useCallback(
    async (uploadedUrl: string, caption: string) => {
      setIsLoadingParticipation(true);

      await addRepcardParticipation({
        variables: {
          repCardId: Number(repCard.id),
          slides: [
            {
              slideId: Number(repCard.slides[0].id),
              answerMediaUrl: uploadedUrl,
              answerText: caption,
            },
          ],
          completeParticipation: true,
        },
      });

      await refetchRepcard({
        id: Number(repCard.id),
      });

      setHasCompleted(true);
      setIsLoadingParticipation(false);
    },
    [repCard]
  );

  const getParticipationStatus = useCallback(() => {
    if (repCard?.myConsolidatedParticipation?.totalPending > 0) {
      return 'IN_REVIEW';
    } else if (
      repCard?.myConsolidatedParticipation?.status != 'ONGOING' &&
      !repCard?.myConsolidatedParticipation?.hasBeenApproved &&
      repCard?.myConsolidatedParticipation?.hasBeenRejected
    ) {
      return 'DENIED';
    } else if (repCard?.myConsolidatedParticipation) {
      return 'COMPLETED';
    }

    return 'NONE';
  }, [repCard]);

  const hasUnlockedView =
    (getParticipationStatus() == 'COMPLETED' && repCard?.myConsolidatedParticipation?.hasBeenApproved) ||
    hasCompleted ||
    isSimulation;

  const showUploadBox =
    (!repCard.repeatParticipations && !['COMPLETED', 'IN_REVIEW', 'DENIED'].includes(getParticipationStatus())) ||
    (repCard.repeatParticipations && !['DENIED', 'IN_REVIEW'].includes(getParticipationStatus()));

  return (
    <Flex
      flexDirection="column"
      position="relative"
      __css={{ gap: '20px' }}
      minHeight={!repCard.coverImageUrl ? '396px' : 'unset'}
    >
      {getParticipationStatus() === 'COMPLETED' && (
        <Flex py="2" flexDirection="column" px="64px" justifyContent="center" bg="#fff4f4">
          <Text variant="text5-400" pt="1">
            {'You completed this challenge'}
          </Text>
        </Flex>
      )}

      {['DENIED', 'IN_REVIEW'].includes(getParticipationStatus()) && (
        <Flex py="2" justifyContent="center" bg="#fff4f4">
          <Text variant="text5-400" pt="1">
            {getParticipationStatus() === 'DENIED'
              ? 'Sorry, your submission wasn’t approved'
              : 'Your submission is awaiting approval'}
          </Text>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        __css={{ gap: '10px' }}
        px="24px"
        pb="24px"
        mt={!showUploadBox || (getParticipationStatus() === 'COMPLETED' && hasUnlockedView) ? '' : '48px'}
      >
        {repCard.coverImageUrl && (
          <Box>
            <ImageCDN
              src={repCard.coverImageUrl}
              width="100%"
              borderRadius="8px"
              style={{ objectFit: 'cover', aspectRatio: '1/1' }}
              height="100%"
            />
          </Box>
        )}
        {repCard.slides[0] && <TitleSlide value={repCard.slides[0].questionTitle} size="small" />}

        <Flex flex={1} alignItems="center" justifyItems="center">
          <Flex alignItems="center">
            <GalleryReward
              rewardQuantity={repCard.reward.quantity}
              rewardType={repCard.reward.type}
              rewardMetadata={rewardMetadata}
              earned={hasCompleted && getParticipationStatus() !== 'DENIED'}
              small
            />
          </Flex>
        </Flex>

        {showUploadBox && (
          <GalleryViewFooter
            showUnlockMessage={false}
            repCard={repCard}
            rewardMetadata={rewardMetadata}
            loading={isLoadingParticipation}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default GalleryViewModal;
