import gql from 'graphql-tag';

import { IInteractivity, InteractivitySourceType } from '../../types';

export interface ISearchComments {
  uuid: string;
  user: {
    firstName: string;
    lastName: string;
    userName: string;
    avatarUrl: string;
    urlSlug: string;
  };
  interactivity: IInteractivity;
  text: string;
  createdAt: string;
}

export interface SearchCommentsData {
  searchComments: ISearchComments[];
}

export const SEARCH_COMMENTS = gql`
  query searchComments($uuid: String!, $type: InteractivitySourceType!, $take: Int, $skip: Int) {
    searchComments(uuid: $uuid, type: $type, take: $take, skip: $skip) {
      uuid
      user {
        firstName
        lastName
        userName
        avatarUrl
        urlSlug
      }
      interactivity {
        uuid
        totalHi5s
      }
      text
      createdAt
    }
  }
`;

export interface SearchRecentCommentsData {
  searchRecentComments: ISearchComments[];
}

export interface SearchRecentCommentsVars {
  uuid: string;
  type: InteractivitySourceType;
  take: number;
  referenceUuid: string;
}

export const SEARCH_RECENT_COMMENTS = gql`
  query searchRecentComments($uuid: String!, $type: InteractivitySourceType!, $take: Int, $referenceUuid: String) {
    searchRecentComments(uuid: $uuid, type: $type, take: $take, referenceUuid: $referenceUuid) {
      uuid
      user {
        firstName
        lastName
        userName
        avatarUrl
        urlSlug
      }
      interactivity {
        uuid
        totalHi5s
      }
      text
      createdAt
    }
  }
`;

export interface CreateCommentData {
  createComment: ISearchComments;
}

export const CREATE_COMMENT = gql`
  mutation createComment($uuid: String!, $type: InteractivitySourceType!, $text: String!) {
    createComment(uuid: $uuid, type: $type, text: $text) {
      uuid
      user {
        firstName
        lastName
        userName
        avatarUrl
        urlSlug
      }
      text
      createdAt
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($uuid: String!) {
    deleteComment(uuid: $uuid) {
      uuid
    }
  }
`;
