import { gql } from '@apollo/client';
import { RepCardParticipationStatusType } from 'src/types';

import { RepcardParticipationApproval } from '../../modals/RepCardCompleteModal/graphql/queries/getRepCard';

export const ADD_REPCARD_PARTICIPATION = gql`
  mutation AddRepCardParticipation(
    $slides: [AddRepCardParticipationSlidesInput!]!
    $repCardId: Int!
    $completeParticipation: Boolean!
  ) {
    addRepCardParticipation(slides: $slides, repCardId: $repCardId, completeParticipation: $completeParticipation) {
      tokenId
      reward {
        assetId
      }
      reps
      completedAt
      id
      repCardId
      userId
      status
      rewarded
      approval {
        status
        message
      }
    }
  }
`;

export interface IAddRepcardParticipationData {
  addRepCardParticipation: {
    id: number;
    tokenId: string;
    repCardId: number;
    userId: number;
    status: RepCardParticipationStatusType;
    rewarded: boolean;
    approval?: RepcardParticipationApproval | null;
  };
}

export interface IAddRepcardParticipationVars {
  repCardId: number;
  slides: {
    slideId: number;
    choiceOptionId?: number;
    answerText?: string;
    answerMediaUrl?: string;
  }[];
  completeParticipation: boolean;
}
