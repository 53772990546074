import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Box, Button, Flex } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import ImageCDN from '../../ImageCDN';
import Description from '../Components/Description';
import TitleSlide from '../Components/TitleSlide';

interface ReferenceSlideProps {
  name: string;
  description?: string;
  showBorders?: boolean;
  onSubmit?: () => void;
  referenceImagesUrls?: string[];
}

const ReferenceSlide: React.FC<ReferenceSlideProps> = ({
  name,
  description,
  showBorders = true,
  onSubmit,
  referenceImagesUrls = [],
}) => (
  <Box border={showBorders ? 'thin' : 'none'} borderRadius="md" overflow="hidden" bg="white" pt="2" width="100%">
    <Flex flexDirection="column" p="2">
      {name ? <TitleSlide value={name} /> : <Box width="86%" height="30px" bg="gray02" />}
      {description && <Description>{description}</Description>}
    </Flex>
    <Box>
      {referenceImagesUrls.length === 0 && <Box width="100%" height="196px" bg="gray02" />}
      {referenceImagesUrls.length === 1 && (
        <ImageCDN
          src={referenceImagesUrls[0]}
          borderRadius="none"
          width="100%"
          maxHeight="calc(70vh - 50px)"
          __css={{ objectFit: 'contain' }}
        />
      )}
      {referenceImagesUrls.length > 1 && (
        <Carousel showThumbs={false} emulateTouch>
          {referenceImagesUrls.map((image, idx) => (
            <ImageCDN
              key={idx}
              src={image}
              borderRadius="none"
              width="100%"
              maxHeight="calc(70vh - 50px)"
              __css={{ objectFit: 'contain' }}
            />
          ))}
        </Carousel>
      )}
    </Box>
    <Flex flexDirection="column" p="2" mt="2">
      <Button onClick={onSubmit}>Continue</Button>
    </Flex>
  </Box>
);

export default ReferenceSlide;
