import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

import { IMultipleChoiceOption } from '../../../types';
import ImageCDN from '../../ImageCDN';

type SlidePreviewProps = {
  index: number;
  option: IMultipleChoiceOption;
  selectedOption: number;
  setSelectedOption: (option: number) => void;
};

const ImageOption: React.FC<SlidePreviewProps> = ({ option, selectedOption, setSelectedOption }) => (
  <Box
    data-testid="challenge-image-text-choice-slide"
    border="1px solid black"
    borderRadius="sm"
    style={{ cursor: 'pointer' }}
    color={selectedOption === option.id ? 'white' : 'black'}
    bg={selectedOption === option.id ? 'black' : 'white'}
    onClick={() => setSelectedOption(option.id)}
    overflow="hidden"
  >
    <Flex bg="white" justifyContent="center" alignItems="center" width="100%" height={200}>
      <ImageCDN
        src={option.imageUrl || option.value}
        width="100%"
        height="100%"
        style={{ objectFit: 'cover', borderRadius: 0 }}
      />
    </Flex>
    <Text ml={2} fontWeight="bold" color={selectedOption === option.id ? 'white' : 'black'}>
      {option.label || option.title}
    </Text>
  </Box>
);

export default ImageOption;
