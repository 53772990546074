import { Spinner } from '@styled-icons/evil';
import { Box, Flex, Icon } from '@tyb-u/tyb-ui-components';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from '../../utils';

const HorizontalScroll: React.FC<{
  children: ReactNode;
  height: number;
  disableOnMobile?: boolean;
  hideArrows?: boolean;
  onReachToEnd?: () => void;
  infinityScroll?: {
    reachToEnd: boolean;
    reachToEndIsLoading?: boolean;
  };
}> = ({ children, height: initialHeight, disableOnMobile, hideArrows = false, onReachToEnd, infinityScroll }) => {
  const childrenCanvasRef = useRef(null);
  const [height, setHeight] = useState(initialHeight);
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const [showLeft, setShowLeft] = useState<boolean>(false);
  const [showRight, setShowRight] = useState<boolean>(true);
  const [hasReachEnd, setHasReachEnd] = useState<boolean>(true);
  const [hasReachStart, setHasReachStart] = useState<boolean>(true);

  const size = useWindowSize();
  const theme = useTheme();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[0]);

  const onScroll = useCallback(
    (e) => {
      const { scrollLeft, offsetWidth, scrollWidth } = e.target;

      const hasEnded = offsetWidth + scrollLeft >= scrollWidth;

      if (scrollLeft === 0) {
        setHasReachStart(true);
        setHasReachEnd(false);
      } else if (hasEnded) {
        setHasReachStart(false);
        setHasReachEnd(true);
        onReachToEnd && onReachToEnd();
      } else {
        setHasReachEnd(false);
        setHasReachStart(false);
      }
    },
    [onReachToEnd]
  );

  const resizeInitializer = useCallback(() => {
    const current = childrenCanvasRef.current;

    if (current) {
      current.addEventListener('scroll', onScroll);
      return () => current.removeEventListener('scroll', onScroll);
    }
  }, [childrenCanvasRef.current, infinityScroll?.reachToEnd, onScroll]);

  useEffect(() => {
    if (!childrenCanvasRef.current) return;

    const resizeObserver = new ResizeObserver(() => resizeInitializer());

    resizeObserver.observe(childrenCanvasRef.current);
    return () => resizeObserver.disconnect();
  }, [childrenCanvasRef.current]);

  useEffect(() => {
    if (!childrenCanvasRef.current) return;

    const { scrollWidth, clientWidth, offsetHeight, scrollLeft, offsetWidth } = childrenCanvasRef.current;

    const hasScroll = scrollWidth > clientWidth;

    setHeight(offsetHeight);
    setHasScroll(hasScroll);

    if (scrollLeft == 0 && offsetWidth == scrollWidth) {
      setShowLeft(false);
      setShowRight(false);
      return;
    }

    if (hasReachStart) {
      setShowRight(true);
      setShowLeft(false);
      return;
    }

    const hasMoreResults = scrollWidth > offsetWidth + scrollLeft;

    if (!hasMoreResults && hasReachEnd) {
      if (!infinityScroll || (!infinityScroll.reachToEndIsLoading && infinityScroll.reachToEnd)) {
        setShowRight(false);
      }
      setShowLeft(true);
      return;
    }

    setShowRight(true);
    setShowLeft(true);
  }, [hasReachStart, hasReachEnd, infinityScroll]);

  const onClickRight = () => {
    if (childrenCanvasRef.current) {
      const width = childrenCanvasRef.current.clientWidth;
      childrenCanvasRef.current.scrollLeft += width / 2;
    }
  };

  const onClickLeft = () => {
    if (childrenCanvasRef.current) {
      const width = childrenCanvasRef.current.clientWidth;
      childrenCanvasRef.current.scrollLeft -= width / 2;
    }
  };

  if (disableOnMobile && isMobile) {
    return (
      <Flex flexDirection="column" __css={{ gap: '15px' }}>
        {children}
      </Flex>
    );
  }

  return (
    <Box width="100%" minHeight={height} position="relative">
      <Flex flex={1} flexDirection="column" position="relative">
        <Flex
          ref={childrenCanvasRef}
          flexWrap="nowrap"
          overflowX="auto"
          width="100%"
          position="absolute"
          __css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Flex>{children}</Flex>
        </Flex>
      </Flex>

      {hasScroll && (
        <>
          {showRight && !hideArrows && (
            <Flex
              data-testid="horizontal-scroll"
              onClick={onClickRight}
              position="absolute"
              right="-16px"
              top={height / 2 - 32 / 2}
              __css={{ cursor: 'pointer' }}
              width="32px"
              height="32px"
              borderRadius="100%"
              justifyContent="center"
              alignItems="center"
              elevation="low"
              bg="white"
              color="black"
            >
              {infinityScroll?.reachToEndIsLoading ? <Spinner /> : <Icon.BoxiconsRegular.ChevronRight size={32} />}
            </Flex>
          )}
          {showLeft && !hideArrows && (
            <Flex
              onClick={onClickLeft}
              position="absolute"
              left="-16px"
              top={height / 2 - 32 / 2}
              __css={{ cursor: 'pointer' }}
              width="32px"
              height="32px"
              borderRadius="100%"
              justifyContent="center"
              alignItems="center"
              elevation="low"
              bg="white"
              color="black"
            >
              <Icon.BoxiconsRegular.ChevronLeft size={32} />
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default HorizontalScroll;
