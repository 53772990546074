import { gql } from '@apollo/client';

import { ISocialPlatforms } from '../../types';

export interface VerifySocialAuthTokenData {
  verifySocialAuthToken: boolean;
}

export interface VerifySocialAuthTokenVars {
  platform: ISocialPlatforms;
  brandUuid: string;
}

export interface GetSocialAuthLinkVars {
  platform: ISocialPlatforms;
  brandUuid: string;
}

export interface GetSocialAuthLinkData {
  socialAuthLink: string;
}

export const VERIFY_SOCIAL_AUTH_TOKEN = gql`
  query Query($platform: String!, $brandUuid: String!) {
    verifySocialAuthToken(platform: $platform, brandUuid: $brandUuid)
  }
`;

export const GET_SOCIAL_AUTH_LINK = gql`
  query Query($platform: String!, $brandUuid: String!) {
    socialAuthLink(platform: $platform, brandUuid: $brandUuid)
  }
`;
