import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  IInteractivityCountersData,
  IInteractivityCountersVars,
  INTERACTIVITY_COUNTERS,
} from 'src/graphql/queries/interactivity';

import Comments from '../../components/Comments';
import Loading from '../../components/Loading';
import TitleSlide from '../../components/RepCard/Components/TitleSlide';
import { useAppSelector } from '../../redux/hooks';
import { RepCardData } from '../RepCardCompleteModal/graphql/queries/getRepCard';
import SlideAnswerResult from './components/SlideAnswerResult';
import SlideMultipleOptionsResult from './components/SlideMultipleOptionsResult';
import {
  GET_REPCARD_PARTICIPATION_RESULT,
  GetRepCardParticipationResultVars,
  GetRepCardParticipationResultVarsData,
} from './graphql/queries/getRepCardParticipationResult';

interface IRepCardResultProps {
  prompt: string;
  showBorderBottom?: boolean;
  children: ReactNode;
}

const RepCardResult: React.FC<IRepCardResultProps> = ({ children, prompt, showBorderBottom = true }) => {
  return (
    <Box
      data-testid="challenge-answered-option"
      borderBottom={showBorderBottom ? 'thin' : 'none'}
      borderBottomColor="black"
      py="2"
      px="3"
    >
      <TitleSlide size="big" value={prompt} />
      <Box mt="2">{children}</Box>
    </Box>
  );
};

interface IRepCardActionsProps {
  repCard: RepCardData['repCard'];
}

const RepCardActions: React.FC<IRepCardActionsProps> = ({ repCard }) => {
  const { refetch: refetchInteractivity, data: refreshedInteractivity } = useQuery<
    IInteractivityCountersData,
    IInteractivityCountersVars
  >(INTERACTIVITY_COUNTERS, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      sourceUuid: repCard.uuid,
      sourceType: 'REPCARD',
    },
  });

  const interactivity = useMemo(
    () => refreshedInteractivity?.interactivity || repCard?.interactivity,
    [repCard, refreshedInteractivity]
  );

  return (
    <Flex py="3" px="3" flexDirection="column" flex={1}>
      <Flex justifyContent="space-between" mb="2">
        <Text variant="text5-400">COMMENTS</Text>

        <Flex alignItems="center" __css={{ gap: '5px' }}>
          {/* <Hi5 uuid={repCard?.uuid} total={interactivity?.totalHi5s} type="REPCARD" /> */}
          <Flex pt="2px" alignItems="center" __css={{ gap: '5px' }}>
            <Icon.BoxiconsRegular.Message size={16} />
            <Text variant="text6-400" fontWeight="bold">
              {interactivity?.totalComments || 0}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Comments
        uuid={repCard?.uuid}
        type="REPCARD"
        total={interactivity?.totalComments}
        openUserProfileTab={() => null}
        onSubmit={() => refetchInteractivity()}
      />
    </Flex>
  );
};

interface IRepCardResultsModalProps {
  repCard: RepCardData['repCard'];
  isSimulation: boolean;
  onReSubmit: () => void;
}

const RepCardResultsModal: React.FC<IRepCardResultsModalProps> = ({ repCard, isSimulation, onReSubmit }) => {
  const { data, loading } = useQuery<GetRepCardParticipationResultVarsData, GetRepCardParticipationResultVars>(
    GET_REPCARD_PARTICIPATION_RESULT,
    {
      fetchPolicy: 'no-cache',
      variables: {
        repCardId: Number(repCard.id),
      },
    }
  );

  const getParticipationStatus = useCallback(() => {
    if (repCard?.myConsolidatedParticipation?.totalPending > 0) {
      return 'IN_REVIEW';
    } else if (
      repCard?.myConsolidatedParticipation?.status != 'ONGOING' &&
      !repCard?.myConsolidatedParticipation?.hasBeenApproved &&
      repCard?.myConsolidatedParticipation?.hasBeenRejected
    ) {
      return 'DENIED';
    } else if (repCard?.myConsolidatedParticipation) {
      return 'COMPLETED';
    }

    return 'NONE';
  }, [repCard]);

  const user = useAppSelector((state) => state.user);

  const showAllAnswers = useMemo(() => !repCard.hideCollectibleResponses, [repCard, user]);

  const showApprovalBanner = !isSimulation && ['DENIED', 'IN_REVIEW'].includes(getParticipationStatus());
  return (
    <Box height="100%">
      {showApprovalBanner && (
        <Flex py="2" justifyContent="center" bg="#fff4f4">
          <Text variant="text5-400" pt="1">
            {getParticipationStatus() === 'DENIED'
              ? 'Sorry, your submission wasn’t approved'
              : 'Your submission is awaiting approval'}
          </Text>
        </Flex>
      )}

      <Flex flexDirection="column" pt={showApprovalBanner ? '1' : '3'} width="100%">
        {(!data || loading) && <Loading />}

        <Text px={3} pt={showApprovalBanner ? '2' : '0'} variant="text5-400">
          {showAllAnswers ? 'RESPONSES' : 'YOUR RESPONSE'}
        </Text>
        <Flex flexDirection="column" height="100%">
          {data?.getRepCardParticipationResult?.map((result, index) => (
            <RepCardResult
              key={result.slide.id}
              prompt={result.slide.questionTitle}
              showBorderBottom={
                data?.getRepCardParticipationResult.length - 1 != index || !repCard.hideCollectibleResponses
              }
            >
              {result.slide.type === 'MULTIPLE_CHOICE' && (
                <SlideMultipleOptionsResult
                  results={result.aggregatedAnswers.map((answer) => {
                    return {
                      title: answer.multipleChoiceOption.title,
                      isSelected: !!result.myAnwers.find(
                        (ma) =>
                          ma.multipleChoiceOption.id === answer.multipleChoiceOption.id &&
                          ma.user.userName === user.userName
                      ),
                      percent: answer.count < 1 ? 0 : Math.floor((answer.count / answer.total) * 100),
                      image: answer.multipleChoiceOption.imageUrl,
                    };
                  })}
                />
              )}

              {['TEXT_RESPONSE', 'MEDIA_UPLOAD'].includes(result.slide.type) && (
                <SlideAnswerResult slideId={result.slide.id} />
              )}
            </RepCardResult>
          ))}

          {!repCard.hideCollectibleResponses && <RepCardActions repCard={repCard} />}

          {repCard?.repeatParticipations && !showApprovalBanner && (
            <Flex px={3} width="100%" flexDirection="column" pb="2">
              <Button onClick={onReSubmit}>Submit another response</Button>
            </Flex>
          )}

          {!showAllAnswers && (
            <Flex alignContent={'end'} px={3} justifyContent={showApprovalBanner ? 'flex-start' : 'center'}>
              <Text color="gray04" variant="text6-400" textAlign="center">
                Community responses hidden by admin
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default RepCardResultsModal;
