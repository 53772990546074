import { Box, Button, Flex, Input } from '@tyb-u/tyb-ui-components';
import React, { useState } from 'react';

import TitleSlide from '../Components/TitleSlide';

interface TextResponseSlide {
  name: string;
  showBorders?: boolean;
  onSubmit?: (response: string) => void;
  loading?: boolean;
}

const TextResponseSlide: React.FC<TextResponseSlide> = ({ name, showBorders = true, onSubmit, loading = false }) => {
  const [response, setResponse] = useState<string | null>(null);

  return (
    <Box
      data-testid="challenge-text-response-slide"
      border={showBorders ? 'thin' : 'none'}
      borderRadius="md"
      overflow="hidden"
      bg="white"
      width="100%"
    >
      <Flex flexDirection="column" pb="2" justifyContent="space-between">
        <Flex flex={1}>
          <TitleSlide value={name} />
        </Flex>
      </Flex>

      <Input
        as="textarea"
        placeholder="Type your answer here..."
        name="response-answer"
        height={88}
        onChange={(e) => setResponse(e.target.value)}
        value={response || ''}
      />

      <Flex flexDirection="column" mt="4">
        <Button disabled={response === null || response === '' || loading} onClick={() => onSubmit(response)}>
          {loading ? 'Loading...' : 'Continue'}
        </Button>
      </Flex>
    </Box>
  );
};

export default TextResponseSlide;
